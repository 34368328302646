<template>
  <!--
    Used to scan a network for available IP-Addresses
  -->
  <div class="row">
    <div class="col-12 col-md-6">
      <Portlet
        :title="$t('ipScannerComponent.ipScanner')"
        icon="search-location"
        class="ipScanner"
      >
        <LoadingPlaceholder v-if="loadingAvailableNetworks == true" />
        <template v-else>
          <template v-if="availableNetworks != null">
            <div class="row">
              <div class="col-12 mb-3">
                <label>{{ $t('network') }}</label>
                <select
                  v-model="network"
                  class="form-control"
                  type="text"
                  @change="clear()"
                >
                  <option
                    v-for="(item, index) in availableNetworks"
                    :key="`localDeviceManagementDetailsNetworkOption-${ index }`"
                    :value="item"
                  >
                    {{ `${ item.ipAddress } (${ item.subnetMask })` }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-6 mb-3">
                <label>{{ $t('timeout') }}</label>
                <select
                  v-model="timeout"
                  class="form-control"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    {{ $t('pleaseSelectOne') }}
                  </option>
                  <option :value="1000">
                    1s
                  </option>
                  <option :value="2000">
                    2s
                  </option>
                  <option :value="3000">
                    3s
                  </option>
                  <option :value="4000">
                    4s
                  </option>
                  <option :value="5000">
                    5s
                  </option>
                </select>
              </div>

              <div class="col-12 col-sm-6 mb-3">
                <label>{{ $t('retries') }}</label>
                <input
                  v-model="retryCount"
                  step="1"
                  class="form-control"
                  type="number"
                >
              </div>
            </div>


            <router-link
              :to="{ path: `/installation/${ installationId }/tracker` }"
              class="btn btn-sm btn-secondary"
            >
              <font-awesome-icon
                class="mr-1"
                icon="caret-left"
              />
              <span>
                {{ $t('back') }}
              </span>
            </router-link>
              
            <div class="btn-group float-right">
              <button
                class="btn btn-sm btn-primary"
                @click="scan()"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="search"
                />
                <span>{{ $t('scan') }}</span>
              </button>
              <button
                class="btn btn-sm btn-secondary"
                @click="clear()"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('clear') }}</span>
              </button>
            </div>
            <div class="clearfix" />

            <template v-if="uniqueItems">
              <h3 class="mt-4">
                {{ $t('results') }}
              </h3>
              <hr class="p-0 m-0">
              <ul class="mt-2">
                <li
                  v-for="(address, index) in uniqueItems"
                  :key="`localDeviceManagementDetails-addressListItem-${ index }`"
                >
                  <CopyToClipboard :copy-data="address" />
                </li>
              </ul>
            </template>
          </template>

          <p v-else>
            {{ $t('noDataAvailable') }}
          </p>
        </template>
      </Portlet>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IpScanner',
  components: {
    CopyToClipboard: () => import('@/components/CopyToClipboard.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      network: null,
      timeout: 1000,
      retryCount: 1,
      response: null,
      uniqueItems: null,
      deviceInterval: null,
      devices: null,
      loadingDevices: true,
      cancelDevicesSource: null,
      loadingAvailableNetworks: true,
      availableNetworks: null
    }
  },
  created () {
    this.getAvailableNetworks();
  },
  methods: {
    clear () {
      let url = `/DeviceManagement/Clear?installationId=${ this.installationId }`;
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.delete(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.response = null;
          this.uniqueItems = null;
          this.$snotify.success("The addresses were successfully cleared.");
        });
    },
    scan () {
      let url = `/DeviceManagement/Scan?installationId=${ this.installationId }&ipAddress=${ this.network.ipAddress }&subnetMask=${ this.network.subnetMask }&timeout=${ this.timeout }&retryCount=${ this.retryCount }`;
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.getAddresses();
        });
    },
    getAvailableNetworks () {
      let url = `/DeviceManagement/GetAvailableNetworks?installationId=${ this.installationId }`;
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then(response => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.availableNetworks = response.data;
          this.network = this.availableNetworks[0];
        })
        .finally(() => {
          this.loadingAvailableNetworks = false;
        });
    },
    compareIPAddresses (a, b) {
      const numA = Number(
        a.split('.')
          .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
          .reduce((a, v) => ((a += v), a), 0)
      );
      const numB = Number(
        b.split('.')
          .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
          .reduce((a, v) => ((a += v), a), 0)
      );
      return numA - numB;
    },
    getAddresses () {
      let url = `/DeviceManagement/GetAddresses?installationId=${ this.installationId }`;
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.response = response.data;
          this.uniqueItems = Array.from(new Set(this.response));
          this.uniqueItems = this.uniqueItems.sort(this.compareIPAddresses);
        });
    }
  }
}
</script>

<style scoped>
@media (min-width: 720px) {
  .ipScanner ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}
@media (min-width: 1280px) {
  .ipScanner ul {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
}
@media (min-width: 1920px) {
  .ipScanner ul {
    columns: 4;
    -webkit-columns: 4;
    -moz-columns: 4;
  }
}
</style>
